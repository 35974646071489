<template>
  <FormWrapper :is-loading="isLoading">
    <PageTitle
      title="
Add Tip of The Day"
      slot="title"
      link="/helpContent/addTipOfTheDay"
    />

    <ValidationObserver ref="validator">
      <FormRow>
        <div class="col-sm-6">
          <InputTextArea label="Tip" v-model="form.tip" rules="required" />
        </div>
      </FormRow>
    </ValidationObserver>
    <br />
    <FormRow>
      <div class="col-sm-6">
        <ButtonGroup>
          <FormButton
            type="info"
            @click="
              () => {
                $router.go(-1);
              }
            "
            >Back</FormButton
          >
          <FormButton type="success" @click="sendData()">Save</FormButton>
        </ButtonGroup>
      </div>
    </FormRow>
    <Popup
      title="Success"
      type="success"
      :value="showMessage.isVisible"
      @close="() => (showMessage.isVisible = false)"
      :closable="true"
    >
      {{ showMessage.message }}
      <div slot="footer">
        <FormButton
          @click="
            () => {
              $router.go(-1);
            }
          "
          >Ok</FormButton
        >
      </div>
    </Popup>
    <Popup
      title="Information"
      type="info"
      :value="infoMessage.isVisible"
      @close="() => (infoMessage.isVisible = false)"
      :closable="true"
    >
      {{ infoMessage.message }}
    </Popup>
  </FormWrapper>
</template>

<script>
import FormWrapper from "Components/form/FormWrapper";
import PageTitle from "Components/layout/PageTitle";
import loadingMixin from "Mixins/loadingMixin";
import { addTipOfTheDay, tipOfTheDayById } from "../api";

import { ValidationObserver } from "vee-validate";

export default {
  name: "add",
  mixins: [loadingMixin],
  components: {
    FormWrapper,
    PageTitle,
    ValidationObserver,
  },
  data() {
    return {
      showMessage: {
        isVisible: false,
      },
      infoMessage: {
        isVisible: false,
      },
      form: {
        date: "",
        tip: "",
        id: 0,
      },
    };
  },
  created() {
    if (this.$route.params.id) {
      this.showLoader();
      this.form.id = atob(this.$route.params.id);
      this.getData();
    }
  },
  methods: {
    getData() {
      const data = {
        id: parseInt(this.form.id),
      };
      tipOfTheDayById(data).then((res) => {
        this.form.tip = res.data[0].tip;
      });
      this.hideLoader();
    },
    sendData() {
      this.$refs.validator.validate().then((result) => {
        if (result) {
          this.showMessage.isVisible = false;
          this.infoMessage.isVisible = false;
          const data = {
            date: this.form.date,
            tip: this.form.tip,
            id: this.form.id == 0 ? 0 : parseInt(this.form.id),
          };

          addTipOfTheDay(data).then((res) => {
            if (res.data[0].status == 1) {
              this.form = {};
              this.showMessage.isVisible = true;
              this.showMessage.message = res.data[0].message;
            } else {
              this.infoMessage.isVisible = true;
              this.infoMessage.message = res.data[0].message;
            }
          });
        }
      });
    },
  },
};
</script>
